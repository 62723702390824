import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Tags } from '@tryghost/helpers-gatsby';
import { readingTime as readingTimeHelper } from '@tryghost/helpers';
import styled from 'styled-components';

const StyledPostCard = styled.div`
    background-color: inherit;
    min-height: 350px;
    border-image-slice: 3;
    border-image-width: 3;
    border-image-repeat: stretch;
    border-image-source: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="UTF-8" ?><svg version="1.1" width="8" height="8" xmlns="http://www.w3.org/2000/svg"><path d="M3 1 h1 v1 h-1 z M4 1 h1 v1 h-1 z M2 2 h1 v1 h-1 z M5 2 h1 v1 h-1 z M1 3 h1 v1 h-1 z M6 3 h1 v1 h-1 z M1 4 h1 v1 h-1 z M6 4 h1 v1 h-1 z M2 5 h1 v1 h-1 z M5 5 h1 v1 h-1 z M3 6 h1 v1 h-1 z M4 6 h1 v1 h-1 z" fill="rgb(33,37,41)" /></svg>');
    border-image-outset: 2;
    border-style: solid;
    border-width: 5px;
    padding: 15px;

    .post-card {
        color: inherit;
        text-decoration: none;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 320px;
    }

    .post-card:hover {
        text-decoration: none;
    }

    .post-card-tags {
        margin: 0 0 5px 0;
        font-size: 1.4rem;
        line-height: 1.15em;
        color: var(--color-secondary);
    }

    .post-card-title {
        margin: 15px 0 10px 0;
        padding: 0;
        font-family: 'Press start 2P';
        text-align: left;
    }

    .post-card-top-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .featured {
        font-size: 1rem;
        margin-bottom: 10px;
        background-color: var(--color-secondary);
        border-radius: 4px;
        padding: 0px 7px;
    }

    .post-card-excerpt {
        color: var(--color-secondary);
        font-size: 1.6rem;
        line-height: 1.55em;
    }

    .post-card-image {
        margin: 0 0 10px 0;
        width: auto;
        height: 200px;
        background: var(--color-secondary) no-repeat center center;
        background-size: cover;
    }

    .post-card-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px 0 0 0;
        color: var(--color-secondary);
    }

    .post-card-footer-left {
        display: flex;
        align-items: center;
    }

    .post-card-footer-right {
        display: flex;
        flex-direction: column;
    }

    .post-card-avatar {
        width: 30px;
        height: 30px;
        margin: 0 7px 0 0;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .post-card-avatar .author-profile-image {
        display: block;
        width: 100%;
        background: var(--color-secondary);
        border-radius: 100%;
        object-fit: cover;
    }

    .post-card-avatar .default-avatar {
        width: 26px;
    }
`;

const PostCard = ({ post }) => {
    const url = `/${post.slug}/`;
    const readingTime = readingTimeHelper(post);

    return (
        <StyledPostCard>
            <Link to={url} className="post-card">
                <header className="post-card-header">
                    {post.feature_image && (
                        <div
                            className="post-card-image"
                            style={{
                                backgroundImage: `url(${post.feature_image})`,
                            }}
                        ></div>
                    )}
                    <div className="post-card-top-container">
                        {post.tags && (
                            <div className="post-card-tags">
                                {' '}
                                <Tags
                                    post={post}
                                    visibility="public"
                                    autolink={false}
                                />
                            </div>
                        )}
                        {true && <span className="featured">Featured</span>}
                    </div>
                    <h2 className="post-card-title">{post.title}</h2>
                </header>
                <section className="post-card-excerpt">{post.excerpt}</section>
                <footer className="post-card-footer">
                    <div className="post-card-footer-left">
                        <div className="post-card-avatar">
                            {post.primary_author.profile_image ? (
                                <img
                                    className="author-profile-image"
                                    src={post.primary_author.profile_image}
                                    alt={post.primary_author.name}
                                />
                            ) : (
                                <img
                                    className="default-avatar"
                                    src="/images/icons/avatar.svg"
                                    alt={post.primary_author.name}
                                />
                            )}
                        </div>
                        <span>{post.primary_author.name}</span>
                    </div>
                    <div className="post-card-footer-right">
                        <div>{readingTime}</div>
                    </div>
                </footer>
            </Link>
        </StyledPostCard>
    );
};

PostCard.propTypes = {
    post: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        featured: PropTypes.bool,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            })
        ),
        excerpt: PropTypes.string.isRequired,
        primary_author: PropTypes.shape({
            name: PropTypes.string.isRequired,
            profile_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
};

export default PostCard;
