import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import config from '../../utils/siteConfig';
import PropTypes from 'prop-types';
import logo from '../../images/scblogo.png';

const StyledMast = styled.div`
    .site-logo {
        height: 100px;
        background: rgb(2, 96, 184);
        background: radial-gradient(
            ellipse,
            rgba(2, 96, 184, 1) 60%,
            rgba(0, 212, 255, 0) 78%
        );
    }

    .site-mast {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0.5vw;
    }

    .site-mast-right {
        display: flex;
        align-items: center;
    }

    .site-mast-right .site-nav-item:last-child {
        padding-right: 0;
    }
    .site-nav-icon {
        height: 40px;
        margin: -5px 0 0;
    }
    .site-nav-item {
        display: inline-block;
        padding: 5px 10px;
        font-weight: bold;
        font-size: 2rem;
        color: #fff;
        opacity: 0.7;
    }
`;

const HeaderSiteMast = ({ site }) => {
    const twitterUrl = site.twitter
        ? `https://twitter.com/${site.twitter.replace(/^@/, ``)}`
        : null;

    return (
        <StyledMast>
            <div className="site-mast">
                <div className="site-mast-left">
                    <Link to="/">
                        <img
                            className="site-logo"
                            src={logo}
                            alt={site.title}
                        />
                    </Link>
                </div>
                <div className="site-mast-right">
                    {site.twitter && (
                        <a
                            href={twitterUrl}
                            className="site-nav-item"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                className="site-nav-icon"
                                src="/images/icons/twitter.svg"
                                alt="Twitter"
                            />
                        </a>
                    )}
                    <a
                        className="site-nav-item"
                        href={`https://feedly.com/i/subscription/feed/${config.siteUrl}/rss/`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            className="site-nav-icon"
                            src="/images/icons/rss.svg"
                            alt="RSS Feed"
                        />
                    </a>
                </div>
            </div>
        </StyledMast>
    );
};
HeaderSiteMast.propTypes = {
    site: PropTypes.object.isRequired,
};

export default HeaderSiteMast;
