import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledBanner = styled.div`
    .site-banner {
        max-width: 80%;
        margin: 0 auto;
        padding: 70px 0;
        text-align: center;
        background: rgb(2, 96, 184);
        background: radial-gradient(
            ellipse,
            rgba(2, 96, 184, 1) 50%,
            rgba(0, 212, 255, 0) 70%
        );
    }

    .site-banner-title {
        margin: 0;
        margin-bottom: -5px;
        padding: 0;
        color: whitesmoke;
        text-shadow: 3px -3px 3px 2px white;
        font-size: 4em;
        line-height: 1.5em;
        font-family: 'Press start 2P', cursive;
        word-wrap: wrap;
    }

    .site-banner-desc {
        margin: 5px 0 0 0;
        padding: 0;
        font-size: 2.4rem;
        line-height: 1.3em;
        opacity: 0.7;
        font-family: var(--font-ubuntu-mono);
    }
`;

const HeaderSiteBanner = ({ site }) => (
    <StyledBanner>
        <div className="site-banner">
            {site && site.title && site.description ? (
                <>
                    <h1 className="site-banner-title">{site.title}</h1>
                    <p className="site-banner-desc">{site.description}</p>
                </>
            ) : (
                <h1>¯\_(ツ)_/¯</h1>
            )}
        </div>
    </StyledBanner>
);

HeaderSiteBanner.propTypes = {
    site: PropTypes.object.isRequired,
};

export default HeaderSiteBanner;
