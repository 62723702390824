import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import HeaderSiteMast from './HeaderSiteMast';
import HeaderSiteBanner from './HeaderSiteBanner';
import HeaderSiteNav from './HeaderSiteNav';
import UnderwaterHero from '../../images/smbunderwaterhero.gif';

const StyledHeader = styled.div`
    .site-head {
        padding-top: 20px;
        padding-bottom: 3px;
        color: #fff;
        background: var(--color-html-base);
        background-position: center;
        background-size: cover;
    }
    .header-container {
        max-width: 99%;
        margin: 0 auto;
        padding: 0 1.5vw;
    }
    @media screen, (max-width: 550px) {
        .header-container {
            padding: 0 1px;
        }
    }
`;

const Header = ({ site, isHome }) => (
    <StyledHeader>
        <header
            className="site-head"
            style={{
                backgroundImage: `url(${UnderwaterHero})`,
            }}
        >
            <div className="header-container">
                <HeaderSiteMast site={site} />
                {isHome ? <HeaderSiteBanner site={site} /> : null}
                <HeaderSiteNav site={site} />
            </div>
        </header>
    </StyledHeader>
);

Header.propTypes = {
    site: PropTypes.object.isRequired,
    isHome: PropTypes.bool,
};

export default Header;
