import React from 'react';
// import { Link } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Navigation } from '.';

const StyledNav = styled.div`
    margin-bottom: -0.9em;
    margin-top: 1em;
    border-radius: 5px;
    overflow: hidden;
    padding: 0 5vw;

    @media (max-width: 600px) {
        padding: 0;
    }

    .nes-container.is-dark {
        border-radius: 15px;
        background-color: var(--color-dark-blue);
        margin: 0;
    }
    .nes-container.with-title > .title {
        background-color: inherit;
    }

    a {
        font-family: 'Press start 2P', 'Roboto';
    }

    .site-nav-item {
        display: inline-block;
        padding: 5px 10px;
        font-weight: bold;
        font-size: 2rem;
        color: white;
        opacity: 0.7;
    }

    .site-nav-item:hover {
        text-decoration: none;
        opacity: 1;
    }

    .site-nav-icon {
        height: 30px;
        margin: -5px 5px 0;
    }

    .site-nav {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        background-color: inherit;
    }

    .site-nav-left {
        margin: 0 1px;
        min-width: 25%;
        display: flex;
        justify-content: space-around;
    }

    .site-nav-button {
        display: inline-block;
        padding: 5px 10px;
        color: #fff;
        font-size: 2rem;
        line-height: 1.5em;
        border-radius: var(--radius);
        opacity: 0.7;
    }

    .site-nav-button:hover {
        text-decoration: none;
    }
`;

const HeaderSiteNav = ({ site }) => (
    <StyledNav>
        <div className="nes-container is-dark is-rounded with-title">
            <h6 className="title">Main Menu</h6>
            <nav className="site-nav">
                <div className="site-nav-left">
                    {/* The navigation items as setup in Ghost */}
                    <Navigation
                        data={site.navigation}
                        navClass="site-nav-item"
                    />
                </div>
                <div className="site-nav-right">
                    {/* <Link className="site-nav-button" to="/about">
                        About
                    </Link> */}
                </div>
            </nav>
        </div>
    </StyledNav>
);

HeaderSiteNav.propTypes = {
    site: PropTypes.object.isRequired,
};

export default HeaderSiteNav;
