import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import styled from 'styled-components';

const StyledPagination = styled.div`
    .pagination {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 4vw 0 0;
    }

    .pagination a {
        display: inline-block;
        padding: 10px 15px;
        border: var(--color-border) 1px solid;
        color: var(--color-secondary);
        text-decoration: none;
        font-size: 1.4rem;
        line-height: 1em;
        border-radius: var(--radius);
    }

    .pagination-location {
        position: absolute;
        left: 50%;
        width: 100px;
        margin-left: -50px;
        text-align: center;
        color: var(--color-secondary);
        font-size: 1.3rem;
    }
`;

const Pagination = ({ pageContext }) => {
    const {
        previousPagePath,
        nextPagePath,
        humanPageNumber,
        numberOfPages,
    } = pageContext;

    return (
        <StyledPagination>
            <nav className="pagination" role="navigation">
                <div>
                    {previousPagePath && (
                        <Link to={previousPagePath} rel="prev">
                            Previous
                        </Link>
                    )}
                </div>
                {numberOfPages > 1 && (
                    <div className="pagination-location">
                        Page {humanPageNumber} of {numberOfPages}
                    </div>
                )}
                <div>
                    {nextPagePath && (
                        <Link to={nextPagePath} rel="next">
                            Next
                        </Link>
                    )}
                </div>
            </nav>
        </StyledPagination>
    );
};

Pagination.propTypes = {
    pageContext: PropTypes.object.isRequired,
};

export default Pagination;
