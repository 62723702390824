import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

  :root {
    /* Colours */
    --color-primary: #a366f2;
    --color-html-base: #0260b8;
    --color-base: #add8e6;
    --color-secondary: #81d3e6;
    --color-border: #c7d5d8;
    --color-bg: #161a1c;
    --color-dark: rgb(21, 25, 27);
    --color-dark-blue: #0d2f4a;

    /* Fonts */
    --font-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    --font-serif: Georgia, Times, serif;
    --font-mono: Menlo, Courier, monospace;
    --font-nes: "Press start 2P", "Ubuntu", sans-serif;
    --font-poppins: "Poppins", sans-serif;
    --font-ubuntu-mono: "Ubuntu Mono", sans-serif;
    --font-light: 100;
    --font-normal: 400;
    --font-bold: 700;
    --font-heavy: 800;

    /* Sizes */
    --height: 4rem;
    --margin: 2rem;
    --radius: 0.6rem;
}

  html, body {
    cursor: crosshair;
  }

  body {
    overflow-x: hidden;
    color: #3c484e;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 1.5rem;
    line-height: 1.6em;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0;
    text-rendering: optimizeLegibility;
    background: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

export default GlobalStyle;
