import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import Header from './Header';
import Footer from './Footer';
import styled from 'styled-components';

// Styles
import '../../styles/app.css';
import GlobalStyles from '../../styles/GlobalStyles';

const StyledLayout = styled.div`
    .viewport {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 100vh;
    }

    .container {
        max-width: 1120px;
        margin: 0 auto;
        padding: 0 4vw;
    }

    .content {
        margin: 0 auto;
        font-size: 2rem;
        line-height: 1.7em;
    }

    a:hover,
    :focus {
        text-decoration: none;
        color: white;
    }

    .content-body {
        display: flex;
        flex-direction: column;
        font-family: var(--font-ubuntu-mono);
    }

    .content-body a {
        color: #00ff99;

        &:hover {
            color: white;
        }
    }

    .content-body .kg-bookmark-container {
        color: var(--color-secondary);
    }

    .post-feature-image img {
        margin: 0 0 3vw;
        width: 100%;
        height: 500px;
        object-fit: cover;
    }

    .content-body h1,
    .content-body h2,
    .content-body h3,
    .content-body h4,
    .content-body h5,
    .content-body h6 {
        font-family: var(--font-mono);
        color: var(--color-primary);
    }

    .content-body h1 {
        margin: 1em 0 0.5em 0;
        font-size: 3.4rem;
        font-weight: 700;
    }

    @media (max-width: 500px) {
        .content-body h1 {
            font-size: 2.8rem;
        }
    }

    .content-title {
        margin: 0 0 0.8em;
        font-size: 5rem;

        &.post-title {
            font-family: var(--font-poppins);
            line-height: 1.05;
            color: var(--color-primary);
        }
    }

    @media (max-width: 500px) {
        .content-title {
            margin: 0.8em 0;
            font-size: 3.4rem;
        }

        .content {
            font-size: 1.8rem;
        }
    }

    .content-body h2 {
        margin: 0.8em 0 0.4em 0;
        font-size: 3.2rem;
        font-weight: 700;
    }

    @media (max-width: 500px) {
        .content-body h2 {
            font-size: 2.6rem;
        }
    }

    .content-body h3 {
        margin: 0.5em 0 0.2em 0;
        font-size: 2.8rem;
        font-weight: 700;
    }

    @media (max-width: 500px) {
        .content-body h3 {
            font-size: 2.2rem;
        }
    }

    .content-body h4 {
        margin: 0.5em 0 0.2em 0;
        font-size: 2.4rem;
        font-weight: 700;
    }

    @media (max-width: 500px) {
        .content-body h4 {
            font-size: 2.2rem;
        }
    }

    .content-body h5 {
        display: block;
        margin: 0.5em 0;
        padding: 1em 0 1.5em;
        border: 0;
        font-family: Georgia, serif;
        color: var(--color-primary);
        font-style: italic;
        font-size: 3.2rem;
        line-height: 1.35em;
        text-align: center;
    }

    .content-body h6 {
        margin: 0.5em 0 0.2em 0;
        font-size: 2rem;
        font-weight: 700;
    }

    .content-body figure {
        margin: 0.4em 0 1.6em;
        font-size: 2.8rem;
        font-weight: 700;
    }

    .content-body pre {
        margin: 0.4em 0 1.8em;
        font-size: 1.6rem;
        line-height: 1.4em;
        white-space: pre-wrap;
        padding: 20px;
        background: var(--color-dark-blue);
        color: #fff;
        border-radius: 12px;
        font-family: var(--font-mono);
    }

    .site-main {
        padding: 5vw 0 4vw;
        min-height: 90vh;
        background-color: var(--color-dark);
    }
`;

const DefaultLayout = ({ data, children, bodyClass, isHome }) => {
    const site = data.allGhostSettings.edges[0].node;
    return (
        <StyledLayout>
            <GlobalStyles />
            <Helmet>
                <html lang={site.lang} />
                <style type="text/css">{`${site.codeinjection_styles}`}</style>
                <body className={bodyClass} />
            </Helmet>

            <div className="viewport">
                <div className="viewport-top">
                    <Header site={site} isHome={isHome} />

                    <main className="site-main">{children}</main>
                </div>

                <div className="viewport-bottom">
                    <Footer title={site.title} navigation={site.navigation} />
                </div>
            </div>
        </StyledLayout>
    );
};

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    bodyClass: PropTypes.string,
    isHome: PropTypes.bool,
    data: PropTypes.shape({
        file: PropTypes.object,
        allGhostSettings: PropTypes.object.isRequired,
    }).isRequired,
};

const DefaultLayoutSettingsQuery = (props) => (
    <StaticQuery
        query={graphql`
            query GhostSettings {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
                file(relativePath: { eq: "ghost-icon.png" }) {
                    childImageSharp {
                        fixed(width: 30, height: 30) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `}
        render={(data) => <DefaultLayout data={data} {...props} />}
    />
);

export default DefaultLayoutSettingsQuery;
