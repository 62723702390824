import React from 'react';
import { Link } from 'gatsby';
import { Navigation } from '.';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledFooter = styled.div`
    .site-foot {
        padding: 20px 0 10px 0;
        color: rgba(255, 255, 255, 0.7);
        font-size: 1.3rem;
        background: var(--color-dark-blue);
        font-family: var(--font-mono);
        border-top: 3px solid var(--color-primary);
    }

    .site-foot-nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .site-foot-nav a {
        color: rgba(255, 255, 255, 0.7);
    }

    .site-foot-nav a:hover {
        text-decoration: none;
        color: rgba(255, 255, 255, 1);
    }

    .site-foot-nav-right a {
        display: inline-block;
        padding: 2px 5px;
        margin: 0 5px;
        font-family: var(--font-nes);
    }

    .site-foot-nav-right a:last-child {
        padding-right: 0;
    }
`;

const Footer = ({ title, navigation }) => (
    <StyledFooter>
        <footer className="site-foot">
            <div className="site-foot-nav container">
                <div className="site-foot-nav-left">
                    <Link to="/">{title}</Link> © 2020 &mdash; Try{' '}
                    <a
                        className="site-foot-nav-item"
                        href="https://ghost.org"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Ghost
                    </a>
                    <div style={{ fontSize: '1rem', marginTop: '-0.7rem' }}>
                        Blooper™ is &copy;Nintendo property
                    </div>
                </div>
                <div className="site-foot-nav-right">
                    <Navigation
                        data={navigation}
                        navClass="site-foot-nav-item"
                    />
                </div>
            </div>
        </footer>
    </StyledFooter>
);

Footer.propTypes = {
    title: PropTypes.string.isRequired,
    navigation: PropTypes.array.isRequired,
};

export default Footer;
